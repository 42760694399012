<template>
  <div class="wrap9">
    <div class="container9">
      <h1>404</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>
</style>